type providerType = {
  accessToken?: string;
  attribution?: string;
};
export const mapProviders: Record<string, providerType> = {
  OSM: {
    attribution:
      '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
  },
  thunderforest: {
    accessToken: "db47f5d57bde4a75a6bd250194fbbb47",
    attribution:
      '&copy; <a href="http://www.thunderforest.com/">Thunderforest</a>, &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
  },
  ruben: {
    attribution:
      '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
  },
} as const;
export type mapType = {
  url?: string;
  provider?: keyof typeof mapProviders;
  attribution?: string;
};
export const mapStyles: Record<string, mapType> = {
  simple: {
    url: "https://tile.openstreetmap.org/{z}/{x}/{y}.png",
    provider: "OSM",
  },
  transportDark: {
    url: `https://{s}.tile.thunderforest.com/transport-dark/{z}/{x}/{y}.png?apikey={accessToken}`,
    provider: "thunderforest",
  },
  transport: {
    url: `https://{s}.tile.thunderforest.com/transport/{z}/{x}/{y}.png?apikey={accessToken}`,
    provider: "thunderforest",
  },
  rubenDark: {
    url: "https://tileserver-gl.rubend.nl/styles/rubenDark/256/{z}/{x}/{y}.png",
    provider: "ruben",
  },
  empty: {},
  disabled: {},
} as const;
