<template>
  {{ translations.booleans }}:
  <v-checkbox-btn
    v-for="key in booleanKeys"
    :key="key"
    v-model="config[key]"
    :label="translations.settingItems[key]"
  />
  {{ translations.textboxes }}:
  <v-text-field
    v-for="key in stringKeys"
    :key="key"
    v-model="config[key]"
    :label="translations.settingItems[key]"
  />
  {{ translations.numbers }}:
  <v-text-field
    v-for="key in numberKeys"
    :key="key"
    v-model.number="config[key]"
    type="number"
    :label="translations.settingItems[key]"
  />
  {{ translations.selects }}:
  <v-select
    v-for="key in listKeys"
    :key="key"
    v-model="config[key]"
    :label="translations.settingItems[key]"
    :items="listOptions[key]"
  />
  <v-row>
    <v-btn @click="reset">{{ translations.reset }}</v-btn>
    <v-btn @click="reload">{{ translations.settings_reload }}</v-btn>
  </v-row>
</template>
<script lang="ts" setup>
import translations from "@/translations";
import { ref, watch } from "vue";
import {
  settings,
  saveConfig,
  defaultConfig,
  listOptions,
} from "@/scripts/settings";
import { objectEntries, objectKeys } from "../../utils/tsfixes";
const listKeys = objectKeys(listOptions) as (keyof typeof listOptions)[];
const keys = objectKeys(defaultConfig).filter(
  (item) => !(item in listOptions),
) as (keyof typeof settings)[];
const booleanKeys: (keyof typeof settings)[] = keys.filter(
  (key) => typeof settings[key] === "boolean",
);
const numberKeys: (keyof typeof settings)[] = keys.filter(
  (key) => typeof settings[key] === "number",
);
const stringKeys: (keyof typeof settings)[] = keys.filter(
  (key) => typeof settings[key] === "string",
);

const config = ref(settings);
watch(
  config,
  () => {
    saveConfig();
  },
  { deep: true },
);
function reset() {
  objectEntries(defaultConfig).forEach(([key, value]) => {
    // @ts-ignore
    this.config[key] = value;
  });
}
async function reload() {
  await caches.delete("cache");
  location.reload();
}
</script>
