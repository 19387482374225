<template>
  <v-tooltip :text="label" :location="location">
    <template #activator="{ props: activatorProps }">
      <v-btn
        variant="elevated"
        :href="href"
        :to="to"
        :icon="icon"
        v-bind="activatorProps"
      />
    </template>
  </v-tooltip>
</template>
<script lang="ts" setup>
import { computed } from "vue";
import { RouteLocationRaw } from "vue-router";
const props = defineProps({
  label: {
    type: String,
    required: true,
  },
  destination: {
    type: [String, Object],
    required: true,
  },
  icon: {
    type: String,
    required: true,
  },
  location: {
    type: String as () => "top" | "bottom" | "left" | "right",
    required: false,
    default: "bottom",
  },
  external: {
    type: Boolean,
    required: false,
    default: false,
  },
});
const isExternal = computed(
  () =>
    props.external ||
    (typeof props.destination === "string" &&
      props.destination.includes("http")),
);
const href = computed(() =>
  isExternal.value ? (props.destination as string) : undefined,
);
const to = computed(() =>
  isExternal.value ? undefined : (props.destination as RouteLocationRaw),
);
</script>
