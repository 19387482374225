// Composables
import { createRouter, createWebHistory } from "vue-router";
import { toDefault } from "@/config/state";
import Default from "../layouts/default/Default.vue";

const routes = [
  {
    path: "/",
    component: Default,
    children: [
      {
        path: "",
        name: "Planner",
        component: () => import("../views/Planner.vue"),
      },
      {
        path: "/stop/:id",
        name: "Stop",
        component: () => import("../views/Stop.vue"),
      },
      {
        path: "/trip/:id",
        name: "Trip",
        component: () => import("../views/Trip.vue"),
      },
      {
        path: "/favouriteStops",
        name: "favouriteStops",
        component: () => import("../views/FavouriteStops.vue"),
      },
      {
        path: "/queryLog",
        name: "queryLog",
        component: () => import("@rubennl/querylog/Explorer"),
      },
      {
        path: "/:pathMatch(.*)*",
        name: "NotFound",
        component: () => import("../views/NotFound.vue"),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});
router.beforeEach((to, from, next) => {
  if (to.path != from.path) toDefault();
  next();
});

export default router;
