/**
 * main.ts
 *
 * Bootstraps Vuetify and other plugins then mounts the App`
 */

// Components
import App from "./App.vue";

// Composables
import { createApp, provide, h } from "vue";
import { DefaultApolloClient } from "@vue/apollo-composable";

// Plugins
import { registerPlugins } from "./plugins";
import apolloClient from "@/plugins/apollo";

const app = createApp({
  setup() {
    provide(DefaultApolloClient, apolloClient);
  },

  render: () => h(App),
});

registerPlugins(app);

app.mount("#app");
