import { reactive } from "vue";
import translations from "../translations";
import { objectEntries } from "../../utils/tsfixes";
export const defaultState = {
  title: translations.title,
  description: translations.description,
};
const state = reactive({
  title: "",
  description: "",
});
export default state;
export function toDefault() {
  objectEntries(defaultState).forEach(([key, value]) => {
    state[key] = value;
  });
}
toDefault();
