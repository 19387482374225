export function dedupe<T>(array: T[]): T[] {
  return Array.from(new Set(array.map((item) => JSON.stringify(item)))).map(
    (item) => JSON.parse(item),
  );
}

export function objectKeys<K extends string, T>(object: Record<K, T>): K[] {
  // eslint-disable-next-line no-restricted-properties
  return Object.keys(object) as K[];
}
export function objectKeysNumber<T>(object: Record<number, T>): number[] {
  return objectKeys(object).map((key) => +key);
}
export function objectEntries<K extends string, T>(
  object: Record<K, T>,
): [K, T][] {
  return objectKeys(object).map((key) => [key, object[key]]);
}
export function objectEntriesNumber<T>(
  object: Record<number, T>,
): [number, T][] {
  return objectKeysNumber(object).map((key) => [key, object[key]]);
}

export function objectFromEntries<K extends PropertyKey, T>(
  entries: [K, T][],
): Record<K, T> {
  // eslint-disable-next-line no-restricted-properties
  return Object.fromEntries(entries) as Record<K, T>;
}

export function notEmpty<T>(value: T | undefined | null): value is T {
  // if (value instanceof Array) return value.length > 0; //maybe later. Might not match function usages
  return value !== undefined && value !== null;
}
